import * as __grid from '@syncfusion/ej2-grids/grid';
import * as _base from '@syncfusion/ej2-base';
import * as _data from '@syncfusion/ej2-data';
import * as _inputs from '@syncfusion/ej2-inputs';
import * as _navigations from '@syncfusion/ej2-navigations';
import * as __contextmenu from '@syncfusion/ej2-navigations/context-menu';
import * as __toolbar from '@syncfusion/ej2-navigations/toolbar';
import * as _calendars from '@syncfusion/ej2-calendars';
import * as __datepicker from '@syncfusion/ej2-calendars/datepicker';
import * as _dropdowns from '@syncfusion/ej2-dropdowns';
import * as __autocomplete from '@syncfusion/ej2-dropdowns/auto-complete';
import * as __dropdownlist from '@syncfusion/ej2-dropdowns/drop-down-list';
import * as _popups from '@syncfusion/ej2-popups';
import * as _buttons from '@syncfusion/ej2-buttons';
import * as _excelexport from '@syncfusion/ej2-excel-export';
import * as _pdfexport from '@syncfusion/ej2-pdf-export';
import * as _fileutils from '@syncfusion/ej2-file-utils';
import * as _compression from '@syncfusion/ej2-compression';
import * as _splitbuttons from '@syncfusion/ej2-splitbuttons';

function copy(copied, first, second, deep) {
    var result = copied || {};
    var length = arguments.length;
    if (deep) {
        length = length - 1;
    }
    var _loop_1 = function (i) {
        if (!arguments_1[i]) {
            return "continue";
        }
        var obj1 = arguments_1[i];
        Object.keys(obj1).forEach(function (key) {
            var src = result[key];
            var copy = obj1[key];
            var clone;
            if (deep && (isObject(copy) || Array.isArray(copy))) {
                if (isObject(copy)) {
                    clone = src ? src : {};
                    result[key] = copy({}, clone, copy, deep);
                }
                else {
                    clone = src ? src : [];
                    result[key] = copy([], clone, copy, deep);
                }
            }
            else {
                result[key] = copy;
            }
        });
    };
    var arguments_1 = arguments;
    for (var i = 1; i < length; i++) {
        _loop_1(i);
    }
    return result;
}

__grid.Grid.Inject(__grid.Filter,__grid.Page,__grid.Selection,__grid.Sort,__grid.Group,__grid.Reorder,__grid.RowDD,__grid.DetailRow,__grid.Toolbar,__grid.Aggregate,__grid.Search,__grid.VirtualScroll,__grid.Edit,__grid.Resize,__grid.CommandColumn,__grid.ContextMenu,__grid.Freeze,__grid.ColumnMenu,__grid.ColumnChooser,__grid.ForeignKey);

var grids = {};
copy(grids, __grid);

var navigations = {};
copy(navigations, __contextmenu);
copy(navigations, __toolbar);

var calendars = {};
copy(calendars, __datepicker);

var dropdowns = {};
copy(dropdowns, __autocomplete);
copy(dropdowns, __dropdownlist);

var base = _base;
var data = _data;
var inputs = _inputs;
var popups = _popups;
var buttons = _buttons;
var excelexport = _excelexport;
var pdfexport = _pdfexport;
var fileutils = _fileutils;
var compression = _compression;
var splitbuttons = _splitbuttons;

export { grids,base,data,inputs,navigations,calendars,dropdowns,popups,buttons,excelexport,pdfexport,fileutils,compression,splitbuttons };